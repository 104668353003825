.dashboard-admin-admin-login {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}
.css-79elbk {
  width: 100%;
  height: 100%;
  position: absolute;
}
.admin-login-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.admin-login-container header section .topo {
  padding: 10px 0;
}

.admin-login-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.admin-login-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.admin-login-container header .menu .menu-container {
  z-index: 1;
}

.admin-login-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.admin-login-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.admin-login-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.admin-login-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.admin-login-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.admin-login-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.admin-login-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.admin-login-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.admin-login-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.admin-login-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.admin-login-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  a:hover {
  filter: brightness(90%);
}

.footer-admin-login {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  height: 80vh;
  margin: 0 auto;
}

.admin-login-container #formContent {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  line-height: normal;
  position: relative;
  width: auto;
  text-decoration: none;
  -webkit-transition: 0.2s background;
  -o-transition: 0.2s background;
  transition: 0.2s background;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 18px;
}

.admin-login-container #formContent form {
  padding: 35px;
}

.admin-login-container #formContent form h1 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 400;
}

.admin-login-container #formContent form .react-switch {
  margin-bottom: 10px;
}

.admin-login-container #formContent form p {
  margin-bottom: 10px;
}

.admin-login-container #formContent form .box-form-group {
  margin-bottom: 15px;
}

.dashboard-admin-login #formContent form .box-form-group:first-of-type {
  display: flex;
  flex-direction: column;
}

.admin-login-container #formContent form .box-form-group label {
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.admin-login-container #formContent form .box-form-group .form-elemento {
  display: block;
  width: 100%;
  height: 34px;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

@media (max-width: 1024px) {
  .admin-login-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .admin-login-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .admin-login-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .admin-login-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .admin-login-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .admin-login-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .admin-login-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .admin-login-container header .menu .menu-container .menu-miolo .register {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .admin-login-container .content .components {
    margin-top: 100px;
  }
  .admin-login-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
  .admin-login-container #formContent {
    margin-top: 30px;
  }
  .admin-login-container #formContent form {
    padding: 15px 35px 20px 35px;
  }
}
