@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
  background: transparent;
}

form input,
textarea {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #f7f7f7;
  padding: 0 24px;
  border-radius: 35px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #41414d;
}

form input:focus,
form textarea:focus {
  background-color: #fff;
  border-bottom: 2px solid #ce1216;
}

.button {
  width: 100%;
  height: 60px;
  background: #ce1216;
  border: 0;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  transition: filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.button:hover {
  filter: brightness(90%);
}

.middle {
  -webkit-box-sizing: initial;
  -moz-box-sizing: initial;
  box-sizing: initial;
  margin: 0 auto;
  max-width: 990px;
  position: relative;
}
