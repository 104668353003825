@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background: #f7f7f7;
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  cursor: pointer;
  background: transparent;
}

form input,
textarea {
  width: 100%;
  height: 60px;
  color: #333;
  border: 1px solid #f7f7f7;
  padding: 0 24px;
  border-radius: 35px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
  border: 1px solid #41414d;
}

form input:focus,
form textarea:focus {
  background-color: #fff;
  border-bottom: 2px solid #ce1216;
}

.button {
  width: 100%;
  height: 60px;
  background: #ce1216;
  border: 0;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.middle {
  box-sizing: content-box;
  box-sizing: initial;
  margin: 0 auto;
  max-width: 990px;
  position: relative;
}

.dashboard-home {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}

/* .home-container {
  width: 100%;
  max-width: 1180px;
  padding: 30px;
  margin: 0 auto;
  height: 100vh;
} */

.home-container .content .components {
  width: 70%;
  float: left;
  margin-bottom: 15px;
}

.home-container .content .actions {
  margin-top: 10px;
  width: 30%;
  float: left;
  height: 194vh;
}
.home-container .content .actions main {
  position: -webkit-sticky;
  position: sticky;
  top: 15px;
}

.home-container header section .topo {
  padding: 10px 0;
}

.home-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.home-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
}

.home-container header .menu .menu-container {
  z-index: 1;
}

.home-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.home-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.home-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.home-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.home-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.home-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.home-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.home-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.home-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.home-container header .menu .menu-container .menu-miolo .register .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.home-container header .menu .menu-container .menu-miolo .register a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.home-container .content .content-4 .title {
  width: 100%;
  margin: 0;
  margin: 55px 0;
  text-align: center;
}

/* .home-container .components .title {
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
} */

.home-container .components .title h1 {
  width: 100%;
  font-size: 28px;
  color: #000;
  margin-bottom: 10px;
}

.home-container .components .conteudo {
  width: 100%;
  margin-right: 0px;
}

.home-container .components .conteudo .box {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  display: inline-block;
  line-height: normal;
  position: relative;
  width: 100%;
  padding: 32px;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.home-container .components .conteudo .box-title {
  width: 100%;
  float: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.home-container .components .conteudo .box-conteudo {
  width: 100%;
  float: left;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 14px;
}

.home-container .components .conteudo .box-conteudo p {
  width: 100%;
  float: left;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: justify;
}

.recuo {
  text-indent: 3em;
}

.home-container .components .conteudo .box-conteudo ul {
  float: left;
  color: #000;
  margin-bottom: 15px;
}

.home-container .components .conteudo .box-conteudo ul li {
  margin-left: 23px;
  font-size: 14px;
  text-align: justify;
}

.home-container .actions .action {
  float: left;
  margin-top: 0px;
  margin-right: 0px;
  padding-left: 20px;
  position: static;
}

.home-container .actions .action ul {
  float: left;
  text-decoration: none;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 0px;
  border-radius: 18px;
  background: #ce1216;
  box-shadow: none;
  color: #fff;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.home-container .actions .action ul li .titulo {
  width: 100%;
  text-align: justify;
  font-size: 15px;
  margin-bottom: 0px;
  color: #fff;
  background-color: #ce1216;
  font-weight: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  border-radius: 18px;
  transition: color 27.931ms ease 0ms, background 27.931ms ease;
}

.home-container .actions .action ul li .conteudo {
  float: left;
  text-align: center;
  padding: 10px 20px;
  width: 100%;
}

.home-container .actions .action ul li .conteudo .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #000;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.home-container .actions .action ul li .conteudoa:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.home-container .actions .action ul li .footer {
  width: 100%;
  float: left;
  margin-bottom: 0px;
  color: #fff;
  background-color: #ce1216;
  font-weight: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  border-radius: 18px;
  transition: color 27.931ms ease 0ms, background 27.931ms ease;
}

.dashboard-home .footer-home {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

@media (max-width: 1024px) {
  .home-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .home-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .home-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .home-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
    height: auto;
  }
  .home-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .home-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .home-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
  .home-container .content .content-4 .title {
    margin: 110px 0 30px 0;
    text-align: center;
    font-size: 14px;
    padding: 0 15px;
  }
  .home-container header .menu .menu-container .menu-miolo {
    padding: 0 20px;
  }
  /* .home-container .actions .action ul li .titulo,
  .home-container .actions .action ul li .footer {
    text-align: center;
  } */
}

.dashboard-login {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}
.css-79elbk {
  width: 100%;
  height: 100%;
  position: absolute;
}
.login-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.login-container header section .topo {
  padding: 10px 0;
}

.login-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.login-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.login-container header .menu .menu-container {
  z-index: 1;
}

.login-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.login-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.login-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.login-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.login-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.login-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.login-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.login-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.login-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.login-container header .menu .menu-container .menu-miolo .register .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.login-container header .menu .menu-container .menu-miolo .register a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.footer-login {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

.login-container .wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  height: 80vh;
  margin: 0 auto;
}

.login-container #formContent {
  margin: 10px 20px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  line-height: normal;
  position: relative;
  width: auto;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.login-container #formContent form {
  padding: 25px;
  margin: 0 15px;
}

.login-container #formContent form h1 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 400;
}

.login-container #formContent form .react-switch {
  margin-bottom: 10px;
}

.login-container #formContent form p {
  margin-bottom: 10px;
}

.login-container #formContent form .box-form-group {
  margin-bottom: 15px;
}

.dashboard-login #formContent form .box-form-group:first-of-type {
  display: flex;
  align-items: left;
  flex-direction: column;
}

.login-container #formContent form .box-form-group label {
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.login-container #formContent form .box-form-group .form-elemento {
  display: block;
  width: 100%;
  height: 34px;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

@media (max-width: 1024px) {
  .login-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .login-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .login-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .login-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .login-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .login-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .login-container header .menu .menu-container .menu-miolo .register {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .login-container .content .components {
    margin-top: 100px;
  }
  .login-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
  .login-container #formContent {
    margin-top: 30px;
  }
  .login-container #formContent form {
    padding: 15px 35px 20px 35px;
  }
}

.dashboard-profile {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}

.profile-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.profile-container header section .topo {
  padding: 10px 0;
}

.profile-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.profile-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.profile-container header .menu .menu-container {
  z-index: 1;
}

.profile-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.profile-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.profile-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.profile-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.profile-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.profile-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.profile-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.profile-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.profile-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.profile-container header .menu .menu-container .menu-miolo .register .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.profile-container header .menu .menu-container .menu-miolo .register a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

/* conetudo */
.profile-container .components .title {
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
}

.profile-container .components .title h1 {
  width: 100%;
  font-size: 36px;
  color: #000;
  margin-bottom: 10px;
}

.profile-container .components .conteudo {
  width: 100%;
  margin-right: 0px;
}

.profile-container .components .conteudo .box {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  display: inline-block;
  line-height: normal;
  position: relative;
  width: 100%;
  padding: 32px;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.profile-container .components .conteudo .box-title {
  width: 100%;
  float: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.profile-container .components .conteudo .box-status {
  width: 100%;
  float: left;
  font-size: 18px;
  margin-bottom: 5px;
  color: #000;
}

.profile-container .components .conteudo .box-message {
  width: 100%;
  float: left;
  font-size: 18px;
  margin-bottom: 10px;
  color: #000;
}

.profile-container .components .conteudo hr {
  margin-bottom: 10px;
}

.profile-container .components .conteudo .box-conteudo {
  width: 100%;
  float: left;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 14px;
}

.profile-container .components .conteudo .box-conteudo p {
  width: 100%;
  float: left;
  font-size: 16px;
  margin-bottom: 10px;
}

.profile-container .components .conteudo .box-conteudo ul {
  width: 90%;
  float: left;
  color: #000;
  margin-bottom: 15px;
}

.profile-container .components .conteudo .box-conteudo ul li {
  margin-left: 23px;
  font-size: 14px;
}

.profile-container .components .conteudo .action {
  float: left;
  margin-top: 0px;
  margin-right: 0px;
  position: static;
}

.profile-container .components .conteudo .action ul {
  float: left;
  list-style-type: none;
}
.profile-container .components .conteudo .action ul li {
  text-decoration: none;
}
.profile-container .components .conteudo .action ul li .titulo {
  width: 100%;
  float: left;
  font-size: 22px;
  margin-bottom: 0px;
  text-decoration: none;
  color: #fff;
  background-color: #ce1216;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  border-radius: 18px;
  transition: color 27.931ms ease 0ms, background 27.931ms ease;
}

.profile-container .components .conteudo .action ul li .conteudo {
  float: left;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.profile-container .components .conteudo .action ul li .conteudo .button {
  width: 100%;
  height: 50px;
  min-width: 265px;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.profile-container .components .conteudo .action ul li .conteudo a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.dashboard-profile .footer-profile {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

@media (max-width: 1024px) {
  .profile-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .profile-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .profile-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profile-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .profile-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .profile-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .profile-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .profile-container .content .components {
    margin-top: 100px;
  }
  .profile-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
}

.dashboard-solicitation {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}
.css-79elbk {
  width: 100%;
  height: 100%;
  position: absolute;
}
.solicitation-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.solicitation-container header section .topo {
  padding: 10px 0;
}

.solicitation-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.solicitation-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.solicitation-container header .menu .menu-container {
  z-index: 1;
}

.solicitation-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.solicitation-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.solicitation-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.solicitation-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.solicitation-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.solicitation-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.solicitation-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.solicitation-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.solicitation-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.solicitation-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.solicitation-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

/* conetudo */
.solicitation-container .components .title {
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
}

.solicitation-container .components .title h1 {
  width: 100%;
  font-size: 36px;
  color: #000;
  margin-bottom: 10px;
}

.solicitation-container .components .conteudo {
  width: 100%;
  margin-right: 0px;
}

.solicitation-container .components .conteudo .box {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  line-height: normal;
  position: relative;
  width: auto;
  padding: 32px;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.solicitation-container .components .conteudo .box .box-form-group {
  max-width: 425px;
  margin-bottom: 15px;
}

.solicitation-container .components .conteudo .box .box-form-group textarea {
  max-width: 425px;
  min-height: 100px;
}

.solicitation-container .components .conteudo .box .box-form-group label {
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.solicitation-container
  .components
  .conteudo
  .box
  .box-form-group
  .form-elemento {
  display: block;
  width: 100%;
  height: 34px;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

.solicitation-container
  .components
  .conteudo
  .box
  .box-form-group
  .form-elemento:focus {
  border: 1px solid #ce1216;
}

.solicitation-container .components .conteudo .box-title {
  width: 100%;
  float: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.solicitation-container .components .conteudo .box-conteudo {
  width: 100%;
  float: left;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 14px;
  margin-bottom: 15px;
}

.solicitation-container .components .conteudo .box-conteudo p {
  width: 100%;
  float: left;
  font-size: 14px;
  margin-bottom: 15px;
}

.solicitation-container .components .conteudo .box-conteudo ul {
  width: 90%;
  float: left;
  color: #000;
  margin-bottom: 15px;
}

.solicitation-container .components .conteudo .box-conteudo ul li {
  margin-left: 23px;
  font-size: 14px;
}

.solicitation-container .components .conteudo .action {
  float: left;
  margin-top: 0px;
  margin-right: 0px;
  position: static;
}

.solicitation-container .components .conteudo .action ul {
  float: left;
  list-style-type: none;
}
.solicitation-container .components .conteudo .action ul li {
  text-decoration: none;
}
.solicitation-container .components .conteudo .action ul li .titulo {
  width: 100%;
  float: left;
  font-size: 22px;
  margin-bottom: 0px;
  text-decoration: none;
  color: #fff;
  background-color: #ce1216;
  font-weight: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  border-radius: 18px;
  transition: color 27.931ms ease 0ms, background 27.931ms ease;
}

.solicitation-container .components .conteudo .action ul li .conteudo {
  float: left;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.solicitation-container .components .conteudo .action ul li .conteudo .button {
  width: 100%;
  height: 50px;
  min-width: 265px;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.solicitation-container .components .conteudo .action ul li .conteudo a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.dashboard-solicitation .footer-solicitation {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

@media (max-width: 1024px) {
  .solicitation-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .solicitation-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .solicitation-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .solicitation-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .solicitation-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .solicitation-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .solicitation-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .solicitation-container .content .components {
    margin-top: 100px;
  }
  .solicitation-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
  .solicitation-container .components .conteudo .box {
    padding: 25px;
  }
}

.dashboard-admin-dashboard {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}

.dashboard-admin-dashboard button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  background: #f4f4f4;
}

.admin-dashboard-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.admin-dashboard-container header section .topo {
  padding: 10px 0;
}

.admin-dashboard-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.admin-dashboard-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.admin-dashboard-container header .menu .menu-container {
  z-index: 1;
}

.admin-dashboard-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.admin-dashboard-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.admin-dashboard-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.admin-dashboard-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.admin-dashboard-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.admin-dashboard-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.admin-dashboard-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.admin-dashboard-container
  header
  .menu
  .menu-container
  .menu-miolo
  nav
  ul
  li
  a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.admin-dashboard-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.admin-dashboard-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.admin-dashboard-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

/* conetudo */
.admin-dashboard-container .components .title {
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
}

.admin-dashboard-container .components .title h1 {
  width: 100%;
  font-size: 36px;
  color: #000;
  margin-bottom: 10px;
}

.admin-dashboard-container .components .conteudo {
  width: 100%;
  margin-right: 0px;
}

.admin-dashboard-container .components .conteudo .box {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  display: inline-block;
  line-height: normal;
  position: relative;
  width: 100%;
  padding: 32px;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.admin-dashboard-container .components .conteudo .box-title {
  width: 100%;
  float: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.admin-dashboard-container .components .conteudo .box-status {
  width: 100%;
  float: left;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #000;
}

.admin-dashboard-container .components .conteudo .box-conteudo {
  width: 100%;
  float: left;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 14px;
  margin-bottom: 15px;
}

.admin-dashboard-container .components .conteudo .box-conteudo p {
  width: 100%;
  float: left;
  font-size: 16px;
  margin-bottom: 15px;
}

.admin-dashboard-container .components .conteudo .box-conteudo ul {
  width: 90%;
  float: left;
  color: #000;
  margin-bottom: 15px;
}

.admin-dashboard-container .components .conteudo .box-conteudo ul li {
  margin-left: 23px;
  font-size: 14px;
}

.admin-dashboard-container .components .conteudo .action {
  float: left;
  margin-top: 0px;
  margin-right: 0px;
  position: static;
}

.admin-dashboard-container .components .conteudo .action ul {
  float: left;
  list-style-type: none;
}
.admin-dashboard-container .components .conteudo .action ul li {
  text-decoration: none;
}
.admin-dashboard-container .components .conteudo .action ul li .titulo {
  width: 100%;
  float: left;
  font-size: 22px;
  margin-bottom: 0px;
  text-decoration: none;
  color: #fff;
  background-color: #ce1216;
  cursor: pointer;
  padding: 20px 20px 20px 20px;
  border-radius: 18px;
  transition: color 27.931ms ease 0ms, background 27.931ms ease;
}

.admin-dashboard-container .components .conteudo .action ul li .conteudo {
  float: left;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

.admin-dashboard-container
  .components
  .conteudo
  .action
  ul
  li
  .conteudo
  .button {
  width: 100%;
  height: 50px;
  min-width: 265px;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.button-file {
  height: 50px;
  border-radius: 35px;
  color: #ce1216;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  padding: 0 10px;
}

.admin-dashboard-container
  .components
  .conteudo
  .action
  ul
  li
  .conteudo
  a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.dashboard-admin-dashboard .footer-admin-dashboard {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

@media (max-width: 1024px) {
  .admin-dashboard-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .admin-dashboard-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .admin-dashboard-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .admin-dashboard-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .admin-dashboard-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .admin-dashboard-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .admin-dashboard-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .admin-dashboard-container .content .components {
    margin-top: 100px;
  }
  .admin-dashboard-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
}

.dashboard-admin-admin-login {
  margin: 0 auto;
  height: 100vh;
  background: #f6f6f6;
}
.css-79elbk {
  width: 100%;
  height: 100%;
  position: absolute;
}
.admin-login-container .content .components {
  margin-top: 50px;
  width: 100%;
  float: left;
}

.admin-login-container header section .topo {
  padding: 10px 0;
}

.admin-login-container header section .topo .virtex {
  font-weight: bold;
  color: #ce1216;
}

.admin-login-container header .menu {
  height: 95px;
  /* background: #a8a8a8; */
  /* border-top: 1px solid #ce1216; */
  /* border-bottom: 6px solid #ce1216; */
  position: relative;
  z-index: 3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.admin-login-container header .menu .menu-container {
  z-index: 1;
}

.admin-login-container header .menu .menu-container .middle {
  height: 100%;
  position: static;
}

.admin-login-container header .menu .menu-container .menu-miolo {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  /* background-image: radial-gradient(circle, #fff, #fff, #ce1216); */
}

.admin-login-container header .menu .menu-container .menu-miolo .logo {
  float: left;
  display: block;
  height: 59px;
  margin: 18px 0;
}

.admin-login-container header .menu .menu-container .menu-miolo .logo img {
  width: auto;
  height: 100%;
}

.admin-login-container header .menu .menu-container .menu-miolo nav {
  float: left;
  display: flex;
}

.admin-login-container header .menu .menu-container .menu-miolo nav ul {
  display: flex;
}
.admin-login-container header .menu .menu-container .menu-miolo nav ul li {
  display: inline-block;
  margin: 30px 0;
}
.admin-login-container header .menu .menu-container .menu-miolo nav ul li a {
  display: inline-block;
  /* color: #f6f6f6; */
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 9px 16px;
  font-size: 16px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
}

.admin-login-container header .menu .menu-container .menu-miolo .register {
  float: left;
  margin: 22.5px 0;
}

.admin-login-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  .button {
  width: 100%;
  height: 50px;
  min-width: 194px;
  background: #ce1216;
  border-radius: 35px;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  line-height: 50px;
  transition: -webkit-filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
}

.admin-login-container
  header
  .menu
  .menu-container
  .menu-miolo
  .register
  a:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.footer-admin-login {
  display: inline-block;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  /* background-color: #efefef; */
  color: #000;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  height: 80vh;
  margin: 0 auto;
}

.admin-login-container #formContent {
  margin: 10px 0px;
  background: white;
  color: #000000;
  border-bottom: 2px solid #c6c6c6;
  line-height: normal;
  position: relative;
  width: auto;
  text-decoration: none;
  transition: 0.2s background;
  border-radius: 18px;
}

.admin-login-container #formContent form {
  padding: 35px;
}

.admin-login-container #formContent form h1 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: 400;
}

.admin-login-container #formContent form .react-switch {
  margin-bottom: 10px;
}

.admin-login-container #formContent form p {
  margin-bottom: 10px;
}

.admin-login-container #formContent form .box-form-group {
  margin-bottom: 15px;
}

.dashboard-admin-login #formContent form .box-form-group:first-of-type {
  display: flex;
  flex-direction: column;
}

.admin-login-container #formContent form .box-form-group label {
  display: block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.admin-login-container #formContent form .box-form-group .form-elemento {
  display: block;
  width: 100%;
  height: 34px;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 20px;
  color: #999999;
  background-color: #fff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0);
}

@media (max-width: 1024px) {
  .admin-login-container header section .topo {
    padding: 10px 15px;
    text-align: center;
  }
  .admin-login-container header .menu .menu-container .menu-miolo {
    padding: 0 15px;
  }
  .admin-login-container .content .content-4 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .admin-login-container .content .components {
    width: 90%;
    float: left;
    margin-bottom: 15px;
  }

  .admin-login-container .content .actions {
    margin-top: 0px;
    width: 90%;
    float: left;
  }
  .admin-login-container .actions .action {
    padding: 0;
  }
}

@media (max-width: 524px) {
  .admin-login-container header .menu .menu-container .menu-miolo {
    flex-direction: column;
  }
  .admin-login-container header .menu .menu-container .menu-miolo .register {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .admin-login-container .content .components {
    margin-top: 100px;
  }
  .admin-login-container header .menu .menu-container .menu-miolo .logo {
    margin: 15px auto;
  }
  .admin-login-container #formContent {
    margin-top: 30px;
  }
  .admin-login-container #formContent form {
    padding: 15px 35px 20px 35px;
  }
}

